import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";

const { search } = window.location;
const configParamRegex = /config=([\w-]+)/;
const configFile = search.match(configParamRegex)
  ? RegExp.$1 + ".json"
  : "./config.json";

fetch(configFile)
  .then((r) => r.json())
  .then((json) => {
    let config = json;
    ReactDOM.render(
      <React.StrictMode>
        <App config={config} />
      </React.StrictMode>,
      document.getElementById("root")
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
