import React, { useEffect } from "react";
import { AppProvider } from "../context/AppContext";
import PanelMain from "./PanelMain";
import "./App.css";

interface IProps {
  config: any;
}

const App = (props: IProps) => {
  return (
    <div className="app">
      <AppProvider>
        <PanelMain config={props.config} />
      </AppProvider>
    </div>
  );
};

export default App;
