import React from "react";
import Items from "./Items";
import "./PanelBottom.css";

interface IProps {
  config: any;
  open: boolean;
}

const PanelBottom = (props: IProps) => {
  return (
    <div className={props.open ? "bottom open" : "bottom"}>
      <div className="bottom__tab">
        <img className="shadow" src="./images/apps.png" alt="Featured Apps" />
      </div>
      <div className="bottom__items">
        <Items config={props.config} features={props.config.featured} />
      </div>
    </div>
  );
};

export default PanelBottom;
