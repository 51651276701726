import React, { useEffect, useState } from "react";
import Typewriter from "typewriter-effect";
import "./PanelCaptions.css";

interface IProps {
  config: any;
}

const PanelCaptions = (props: IProps) => {
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <div className="captions">
      <Typewriter
        options={{ loop: true, deleteSpeed: 20 }}
        onInit={(typewriter) => {
          typewriter
            .typeString(
              "<span class='captions__title'>" +
                props.config.app.title +
                "</span>"
            )
            .pauseFor(3000)
            .deleteAll()
            .typeString(
              "<br/><span class='captions__subtitle'>" +
                props.config.app.subtitle +
                "</span>"
            )
            .pauseFor(5000)
            .deleteAll()
            .pauseFor(2000)
            .start();
        }}
      />
    </div>
  );
};

export default PanelCaptions;
