import React, { useEffect, useState } from "react";
import PanelHeader from "./PanelHeader";
import PanelCaptions from "./PanelCaptions";
import PanelBottom from "./PanelBottom";
import "./PanelMain.css";

interface IProps {
  config: any;
}

const PanelMain = (props: IProps) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setOpen(!open);
    }, props.config.app.interval);
  }, [props.config]);

  return (
    <div className="main">
      <div className="panel__video">
        <video autoPlay={true} muted={true} loop={true}>
          <source src="./images/city.mp4" type="video/mp4" />
        </video>
      </div>
      <PanelHeader
        config={props.config}
        appsHandler={() => {
          setOpen(!open);
        }}
      />
      <PanelCaptions config={props.config} />
      <PanelBottom config={props.config} open={open} />
    </div>
  );
};

export default PanelMain;
