import React, { useReducer } from "react";

type SetValue = (value: any) => void;

interface IAppState {
  time: number;
}

interface IAppContext {
  state: IAppState;
  setState: SetValue;
}

const initialState: IAppState = {
  time: 0,
};

export const AppContext = React.createContext<IAppContext>({
  state: initialState,
  setState: (val: any) => val,
});

export const AppProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const reducer: (a: IAppState, b: IAppState) => IAppState = (
    currentState,
    newState
  ) => ({
    ...currentState,
    ...newState,
  });
  const [state, setState] = useReducer(reducer, initialState);
  const value = {
    state,
    setState,
  };
  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
};
