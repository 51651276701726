import React from "react";
import "./Items.css";

interface IProps {
  config: any;
  features: Array<any>;
}

const Items = (props: IProps) => {
  const createItems = () => {
    return props.features.map((f: any, idx: number) => {
      return createItem(f, idx);
    });
  };

  const createItem = (f: any, idx: number) => {
    const color = props.config.app.colors[2];
    return (
      <div
        className="item shadow"
        key={idx}
        onClick={() => {
          handleClick(idx);
        }}
      >
        <div className="item__icon">
          <i className={f.icon}></i>
        </div>
        <div className="item__info">
          <div className="item__title">{f.title}</div>
          <div className="item__subtitle">{f.subtitle}</div>
        </div>
        <div
          className="item__soon"
          style={{ display: f.url && f.url !== "" ? "none" : "block" }}
        >
          <img className="shadow" src="./images/soon.png" alt="Coming Soon!" />
        </div>
      </div>
    );
  };

  const handleClick = (idx: number) => {
    const obj = props.features[idx];
    if (obj.url) {
      window.open(obj.url, "_blank")?.focus();
    }
  };
  return <div className="items">{createItems()}</div>;
};

export default Items;
