import React from "react";
import "./PanelHeader.css";

interface IProps {
  config: any;
  appsHandler?: Function;
}

const PanelHeader = (props: IProps) => {
  const clickApps = () => {
    if (props.appsHandler) {
      props.appsHandler();
    }
  };

  const clickEmail = () => {
    const url = props.config.app.links.email;
    window.open(url);
  };

  const clickTwitter = () => {
    const url = props.config.app.links.twitter;
    window.open(url);
  };

  return (
    <div className="panel__header">
      <div className="header__title">
        <img
          className="header__logo shadow"
          src="./images/logo.png"
          alt="Live"
        />
        <img
          className="header__logo2 shadow"
          src="./images/logo2.png"
          alt="Live"
        />
      </div>
      <div className="header__btn" title="Apps" onClick={clickApps}>
        <i className="fa-solid fa-grip"></i>
      </div>
      <div className="header__btn" title="Contact" onClick={clickEmail}>
        <i className="fas fa-envelope"></i>
      </div>
      <div className="header__btn" title="Twitter" onClick={clickTwitter}>
        <i className="fab fa-twitter"></i>
      </div>
    </div>
  );
};

export default PanelHeader;
